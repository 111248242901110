<template>
  <v-container grid-list-xs fluid v-if="!loading">
    <v-row>
      <v-col cols="12">
        <h4>Categories</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          fab
          color="accent"
          right
          top
          absolute
          class="mt-5"
          @click.native.stop="addCategory"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="categories">
      <v-col
        sm="12"
        md="4"
        lg="3"
        v-for="(value, key) in categories"
        :key="key"
      >
        <v-card max-height="400">
          <v-card-title
            height="100"
            :class="
              'py-1 ' +
                (value.isActive ? 'blue' : 'red') +
                ' lighten-3 white--text'
            "
            class="text-no-wrap"
          >
            <v-edit-dialog
              @open="tmp = value.name"
              @save="saveName(value.id, tmp)"
              large
              lazy
            >
              <span class="pt-1">{{ value.name | truncate(50) }}</span>
              <v-spacer></v-spacer>
              <v-icon right small>edit</v-icon>
              <div slot="input" class="mt-3 title">Update category name</div>
              <v-text-field
                slot="input"
                label="Edit"
                v-model="tmp"
                single-line
                autofocus
              >
              </v-text-field>
            </v-edit-dialog>

            <small>{{ value.isActive ? '' : '(NA)' }}</small>
          </v-card-title>

          <v-btn v-if="false" fab small top right absolute @click="expand(key)">
            <v-icon>keyboard_arrow_down</v-icon>
          </v-btn>

          <v-card-text>
            <draggable
              :id="key"
              v-model="products"
              group="products"
              @start="drag = true"
              @end="drag = false"
              @add="newProduct(value.id)"
            >
              <vue-perfect-scrollbar style="height:280px" :settings="settings">
                <v-list
                  v-if="value.items && value.items.length > 0"
                  class="list-group-item"
                >
                  <draggable
                    :id="key"
                    v-model="value.items"
                    group="items"
                    @start="in_category = value.id"
                    @end="saveOrdering"
                  >
                    <v-list-item
                      v-for="(product, key) in productInCategory(value)"
                      :key="key"
                      avatar
                    >
                      <template v-if="product">
                        <v-list-item-action v-if="dishImage(product)">
                          <v-img
                            contain
                            class="mx-1"
                            :src="dishImage(product)"
                          />
                        </v-list-item-action>

                        <v-list-item-content>
                          {{ product.name }}
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon
                            color="red"
                            @click="removeProduct(product.id, value.id)"
                            small
                            >clear</v-icon
                          >
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </draggable>
                </v-list>
                <v-list v-else>
                  <v-list-item> Drag and drop items here. </v-list-item>
                </v-list>
              </vue-perfect-scrollbar>
            </draggable>
          </v-card-text>

          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-content>
                <v-list-item-title>
                  # Items:
                  {{ value.items ? value.items.length : 0 }}</v-list-item-title
                >
              </v-list-item-content>

              <v-row>
                <v-list-item @click="deleteCategory(value.id)">
                  <v-icon color="red" class="mr-3 ">
                    delete
                  </v-icon>
                </v-list-item>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div>
      <h4>Products <v-icon>search</v-icon></h4>

      <v-row>
        <div v-for="(product, key) in dishList" :key="key">
          <draggable
            :id="product.id"
            v-model="products"
            :options="{
              group: { name: 'products', pull: 'clone', put: 'false' }
            }"
            @start="drag = true"
            @end="drag = false"
            :move="chooseProduct"
          >
            <v-col cols="12" sm="3">
              <v-card
                class="ma-2 pa-2 light-blue lighten-5"
                width="200"
                height="200"
              >
                <v-img
                  v-if="dishImage(product)"
                  contain
                  height="100"
                  :src="dishImage(product)"
                >
                </v-img>
                <v-card-title>
                  <h4>{{ product.name }}</h4>
                </v-card-title>
              </v-card>
            </v-col>
          </draggable>
        </div>
      </v-row>
    </div>
    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      heading="Deleting Category"
      message="Are you sure you want to delete this Category?"
      @onConfirm="onDeleteCategory"
    >
    </delete-confirmation-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'

const newName = 'Category'

export default {
  data: () => {
    return {
      tmp: null,
      targetProduct: null,
      category_id: null,
      products: [],
      categories: [],
      in_category: null,
      settings: {
        maxScrollbarLength: 160
      }
    }
  },
  components: {
    draggable
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      dishList: 'dishList'
    })
  },
  mounted() {
    this.$store.dispatch('setLoading', true)
    this.$store.dispatch('loadDishList', { publicOnly: true }).then(() => {
      this.loadCategories()
    })
  },
  methods: {
    loadCategories() {
      this.$store.dispatch('loadCategories').then(list => {
        this.categories = list
        this.$store.dispatch('setLoading', false)
      })
    },
    addProduct(product) {
      console.log('add', product)
    },
    saveOrdering() {
      let category = this.categories.filter(m => m.id == this.in_category)
      this.$store.dispatch('saveCategory', category[0]).then(() => {
        console.log('Category ordering saved for ', this.in_category)
      })
    },
    saveName(item, newName) {
      let category = this.categories.filter(m => m.id == item)
      category = category.length > 0 ? category[0] : []
      category.name = newName

      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('saveCategory', category).then(() => {
        this.$store.dispatch('setLoading', false)
      })
    },
    deleteCategory(id) {
      this.category_id = id
      this.$refs.deleteConfirmationDialog.openDialog()
    },
    onDeleteCategory() {
      console.log('deleting', this.category_id)
      this.$store.dispatch('deleteCategory', this.category_id).then(() => {
        this.loadCategories()
        this.$refs.deleteConfirmationDialog.close()
      })
    },
    productInCategory(items) {
      let list = []

      if (!items) {
        return
      }
      items.items.forEach(pid => {
        if (this.dishList && pid.length > 0) {
          let product = this.dishList.filter(p => p.id === pid)
          list.push(product[0])
        }
      })

      return list
    },
    newProduct: function(id) {
      console.log('newProduct ' + id)
      let category = this.categories.filter(m => m.id == id)
      category = category.length > 0 ? category[0] : []
      category.items = category.items || []
      let list = category.items.filter(p => p === this.targetProduct)
      if (list.length > 0) {
        console.log('already exists')
        return
      }

      category.items.push(this.targetProduct)

      this.$store.dispatch('saveCategory', category).then(() => {
        this.$store.dispatch('setLoading', false)
      })
      //console.log(event)
      //eventBus.$emit('daySelected', event.to.id)
      this.loadCategories()
    },
    chooseProduct: function(event) {
      console.log('chooseProduct ' + event.from.id)

      this.targetProduct = event.from.id
    },
    addCategory() {
      let newCategory = {
        name: newName,
        isActive: true
      }
      if (this.categories[newName]) {
        this.categories[
          newName + '-' + Object.keys(this.categories).length
        ] = newCategory
      } else {
        this.categories[newName] = newCategory
      }

      this.$store.dispatch('setLoading', true)
      this.$store
        .dispatch('saveCategory', this.categories[newName])
        .then(() => {
          this.$store.dispatch('setLoading', false)
          this.loadCategories()
        })
      console.log(this.categories)
    },
    dishImage(dish) {
      return dish && dish.photos && dish.photos.length > 0
        ? dish.photos[0].url || dish.photos[0].imageUrl
        : null
    },
    removeProduct: function(id, mid) {
      console.log(`Remove ${id} from  ${mid}`)
      let category = this.categories.filter(m => m.id == mid)

      category = category.length > 0 ? category[0] : null
      if (!category) {
        return
      }
      const list = category.items.filter(item => item !== id)
      category.items = list
      this.$store.dispatch('saveCategory', category).then(() => {
        this.$store.dispatch('setLoading', false)
      })
    }
  }
}
</script>

<style></style>
